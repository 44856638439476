<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        class="shrink"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.suggested="{item}">
        {{ item.suggested ? 'Podbita' : 'Niepodbita' }}
      </template>
      <template v-slot:item.actions="{on, item}">
        <v-icon v-on="on" size="24" @click="unbidCategory(item)">mdi-refresh</v-icon>
      </template>
    </v-data-table>
    <Confirm ref="modalConfirm" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import Confirm from '@/components/UI/Confirm'

const CategoryRepository = RepositoryFactory.get('categories')

export default {
  mixins: [paginationMixin],
  components: {Confirm},
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'id', value: 'id', width: '5%'},
        {text: 'Nazwa kategorii', value: 'name', width: '50%'},
        {text: 'Status', value: 'suggested', width: '10%'},
        {text: '', value: 'actions', width: '7%'},
        // { text: 'Ost. sprzedaż', value: 'lastSale' },
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchAllCategories', 'showNotification']),
    async fetchItems() {
      const {search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search,
      })
      this.$data.$_paginationMixin_totalCount = await this.fetchAllCategories({
        ...query,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async unbidCategory(item) {
      const {name, id} = item
      const res = await this.$refs.modalConfirm.open({
        title: `Zerowanie stawek`,
        message: `Czy na pewno chcesz wyzerować stawki dla kategorii "${name}"?`,
      })
      if (res) {
        try {
          await CategoryRepository.unbidCategory(id)
          this.showNotification({
            message: 'NOTY_BIDDING_CATEGORIES_UNBIDDED',
            type: 'success',
          })
          await this.fetchItems()
        } catch (e) {
          this.showNotification({
            message: e.response.data,
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.categories.allCategories,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
      }
    },
  },
}
</script>

<style></style>
